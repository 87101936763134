import React from 'react';
import { Box } from 'grommet';

const Separator = ({ ...rest }) => (
  <Box
    margin={{ vertical: 'small' }}
    border={{ side: 'bottom', size: 'small' }}
    width="30%"
    {...rest}
  />
);

export default Separator;
